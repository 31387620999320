/* Blog Post Elements */
/* ---- Blog loop elements style 1 ------ */

.category-slider {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  border-top: 1px solid #f9f0e7;
  border-bottom: 1px solid #f9f0e7;
  margin: 1rem auto;
  text-align: center;
}

.category-slider .category {
  padding: 1rem;
  white-space: nowrap;
}

.banner-inner .category {
  margin-bottom: 1rem;
}

.active {
  color: #be9a85;
}

.blog-masonry {
  columns: 3;
  column-gap: 0;
}

.blog-masonry .masonry-item {
  -webkit-column-break-inside: avoid; /* Chrome, Safari */
  page-break-inside: avoid;           /* Theoretically FF 20+ */
  break-inside: avoid-column;
}

.masonry-item h3 {
  margin-bottom: 0;
}

.blog-item .bump {
  margin-left: 2rem;
  margin-top: -4rem;
  background-color: white;
  z-index: 3;
  position: relative;
  padding: 1.5rem;
}

.blog-item .image-overlay {
  transition: 0.5s all;
  opacity: 0;
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom: 0;
}

.blog-item .image-overlay h3 {
  font-size: 1.3rem;
  letter-spacing: 3px;
  color: #d7b1a2;
}

.blog-item:hover .image-overlay {
  opacity: 1;
  background-color: rgba(249,240,231,0.9);
}


.blog-loop .blog-item {
  color: inherit;
  text-decoration: none;
  width: 100%;
  margin: 0;
  display:block;
}

.blog-loop .bg-image {
  padding-bottom: 60%;
  margin-bottom: 0.5rem;
}

.blog-loop img,
.post-body img {
    margin-bottom: 2vh;
}

/* ---- Blog loop elements style 2 ------ */
.blog-item-wide .bg-image {
  padding-bottom: 30%;
}

.text-bump { /* selects every second one and reverses it */
  margin-left: -2rem;
  background-color: rgb(240,240,245);
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.blog-item-wide:nth-child(odd) { /* selects every second one and reverses it */
  flex-direction: row-reverse;
}

.blog-item-wide:nth-child(odd) .text-bump { /* selects every second one and reverses it */
  margin-right: -2rem;
  margin-left: 0;
}


/* ---- Blog post pages ------ */
.post-date {
margin-top: 1rem;
font-size: 0.9rem;
}

.post-body {
  padding-right: 3%;
}

.post-meta {
  font-size: 0.9rem;
}

.down-arrow {
  display: block;
  margin-top: 4rem;
}

.post-content .emphasis {
  font-size: 2.5rem;
  text-decoration: underline;
  line-height: 1.4;
}

.sidebar > div {
  border: 1px solid rgb(245,245,245);
  padding: 2rem;
  margin: 2vh 0;
}

.category {
  display: inline-block;
  text-decoration: none;
  font-family: '"Source Sans Pro"';
  letter-spacing: 1px;
  /* color: rgb(90,90,95);
  border: 1px solid rgb(90,90,95);
  margin: 2px;
  padding: 2px 10px;
  border-radius: 3px; */
  text-transform: uppercase;
  font-size: 0.8rem;
}

a.category:hover {
  border-color: #be9a85; /* featured color */
  color: #be9a85; /* featured color */
}
.social-share {
  position: fixed;
  left: 2rem;
  top: 35vh;
  z-index: 2;
}

.social-share nav [class*=fa] {
  padding: 0.5rem;
  display: block;
}

.mob-social-share h4,
.social-share h4 {
  font-size: 0.9rem;
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 1px;
  font-weight: 600;
}

.mob-social-share h4,
.mob-social-share nav {
  display: inline-block;
}

.social-share h4 {
  transform: rotate(270deg);
  position: relative;
  left: -0.5rem;
  top: 1.8rem;
}

/* Blog post gallery */

.gallery {
  font-size: 0;
}

.gallery img {
  margin: 0.5rem;
  width: calc(100% - 1rem);
  display: inline-block;
}

.gallery .half {
  width: calc(50% - 1rem);
}
