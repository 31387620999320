/* Slick slider */
.slick-prev {
  left: 5%;
}

.slick-next {
  right: 5%;
}

.slick-next:before, .slick-prev:before {
    font-family: inherit;
    color: rgb(60,60,60);
}
