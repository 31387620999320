
  /* ---------------  Button Styles  --------- */
  /* We have created lots of different button styles, you probably don't need to have quite this many styles depending on your site */

  .button,
  .button-solid,
  .button-inverted {
    text-decoration: none;
    color: #be9a85;
    border: 2px solid;
    padding: 0.6rem 2rem;
    margin-top: 1rem;
    display: inline-block;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 400;
    letter-spacing: 1px;
    font-family: "Source Sans Pro", sans-serif;
  }

  .button:hover {
    color: rgb(60, 60, 60);
  }

  .button.inverted {   /* this is for a button on a dark background */
    border: 2px solid white;
    color: white;
  }

  .button.inverted:hover {
    color: #be9a85;
    border-color: #be9a85;
  }

  .button-solid {
    background-color: rgb(60,60,60);
    color: white;
    border: none;
    padding: 0.8rem 2rem 0.6rem;
  }

  .button-solid:hover {
    background-color: #be9a85;
  }


    /* ----- FORMS --------------------------- */

    button, input, select, textarea {
      font-family: inherit;
      font-size: 100%;
      box-sizing: border-box;
      -webkit-appearance: none;
    }

    input,
    textarea {
      border-style: solid;
      border: 2px solid #f9f0e7;
      padding: 0.8em;
      display: block;
      width: 100%;
      font-size: 1rem;
      vertical-align: top;
    }

    input:focus,
    textarea:focus {
      outline: none;
      border-color: #be9a85;
    }

    textarea {
      min-height: 6rem;
    }

    input[type="submit"] {
      width: auto;
      display: block;
    }

    // Work with me form

    .enquiry input {
      font-family: orpheuspro, serif;
    }


    .floating-input {
      position: relative;
      padding-top: 1rem;
      margin-bottom: 3rem;
    }

    .input-text {
      font-size: 1.2rem;
      line-height: 1.2;
      display: block;
      margin: 0;
      width: 100%;
      border: none;
      border-bottom: 2px solid rgb(95,90,90);
      transition: all 0.5s;
      padding: 0.7rem 0 0.5rem;
      background-color: rgba(0,0,0,0);
    }

    .enquiry textarea {
      resize: none;
    }

    .input-text:focus {
      border-color: #d7b1a2;
    }

    .input-label {
      display: block;
      position: absolute;
      top:0;
      font-family: "Source Sans Pro";
      text-transform: uppercase;
      font-size: 0.8rem;
      letter-spacing: 1px;
      color: rgb(90,90,90);
    }

    .input-text:placeholder-shown + .input-label {
      /* While the placeholder is visible, we want to hide the label. */
      visibility: hidden;
      z-index: -1;
    }

    .input-text:not(:placeholder-shown) + .input-label,
    .input-text:focus:not(:placeholder-shown) + .input-label {
      /**
       * While the placeholder is not shown - i.e. the input has a value - we want
       * to animate the label into the floated position.
       */
      visibility: visible;
      z-index: 1;
      opacity: 1;
      /**
       * These props will be added in the demo once variables are defined:
       *
       * transform
       * transition
       *
       * They will give the label floated positioning and styling.
       */
    }

     // Course Subscribe

     .cta .button-solid {
       background-color: rgb(60, 60, 60);
       border-color: rgb(60, 60, 60);
     }

     .cta .button-solid:hover {
       background-color: #f9f0e7;
       border-color: #f9f0e7;
       color: rgb(60, 60, 60);
     }

     .cta input {
       border-color: white;
     }



     @media(max-width:768px) {
       .email-signup input {
         width: 100%;
         max-width: 100%;
       }
     }
