/*-----  CSS RESET  ------*/

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth; /* this enables smooth scrolling between internal links */
  }

  /* this overrides the default padding and margin on gridlex elements */
 [class*=grid] {   margin: 0;  }
 [class*=col]  {   padding: 0; }

 video {
   max-width: 100%;
 }

 video:focus {
   outline: none;
 }

  // section {
  //   overflow-y: auto;
  // }

  .relative {
    position: relative;
  }

  img {
    max-width: 100%; /* this ensures images never break their container */
    height: auto;
    display: inline-block;
  }

  .bg-image {
    background-size: cover;
    background-position: center;
    background-color: #F2EEE9;
    background-repeat: no-repeat;
    opacity: 1;
    transition: opacity 1s;
  }

  .bg-image.lazy {
    background-image: none!important;
    opacity: 0;
  }

  a.bg-image {
    display: block;
  }

  .project-gallery {
    display: flex;
    flex-flow: row wrap;
  }

  .project-gallery a {
    display: block;
  }

    .col-one-third {
      flex-basis: 33.33%;
      max-width: 33.33333%;
    }

    .col-one-half {
      flex-basis: 50%;
      max-width: 50%;
    }

    .col-two-thirds {
      flex-basis: 66.66%;
      max-width: 66.66%;
    }

    .col-full-width {
      flex-basis: 100%;
      max-width: 100%;
    }

    .one-third {
      padding-bottom: 123%;
    }

    .one-half {
      padding-bottom: 115%;
    }

    .two-thirds {
      padding-bottom: 60%;
    }

    .full-width {
      padding-bottom: 60%;
    }


      .tall-portrait,
      .portrait-tall {
        padding-bottom: 175%;
      }

      .portrait {
        padding-bottom: 115%;
      }

      .square {
        padding-bottom: 100%;
      }

      .landscape {
        padding-bottom: 60%;
      }

      .tall-landscape {
        padding-bottom: 69%;;
      }

      .wide-landscape {
        padding-bottom: 40%;
      }



  /* ------------- Utility Classes --------------  */

  .left, .align-to-left     {  text-align: left;     }
  .right, .align-to-right     {  text-align: right;    }
  .centered, .align-to-center  {  text-align: center;   }

  .padded, .padded-5p      {  padding: 5%;    }
  .padded-tall    {  padding: 15vh 5%;    }
  .padded-10   {  padding: 10%;    }
  .padded-v {  padding-top: 5%; padding-bottom: 5%;  }
  .padded-5vw { padding: 5vw;  }
  .padded-05 {  padding: 0.5rem;  }
  .padded-1 {  padding: 1rem;  }
  .padded-2 {  padding: 2rem;  }
  .padded-2-h {  padding: 0 2rem;  }
  .padded-2-v {  padding: 2rem 0;  }
  .padded-l { padding-left: 5%; }
  .padded-l-0 { padding-left: 0; }
  .padded-r { padding-right: 5%; }
  .padded-t-0 {padding-top: 0;}
  .padded-t-2 {padding-top: 2rem;}
  .padded-t-10 {padding-top: 10rem;}

  .padded-5-10 {padding: 5% 10%}

  .margin-0 {margin: 0;}
  .margin-05 {margin: 0.5rem;}
  .margin-1 {margin: 1rem;}
  .margin-top-1 { margin-top: 1rem; }
  .margin-top-2 { margin-top: 2rem; }
  .margin-top-5 { margin-top: 5%; }
  .margin-v-2 { margin: 2rem 0; }
  .margin-b-0 { margin-bottom: 0; }
  .margin-bottom-1 { margin-bottom: 1rem; }
  .margin-bottom-2 { margin-bottom: 2rem; }

  .capped-width { max-width: 1100px; margin: auto;}
  .capped-width-narrow { max-width: 1000px; margin: auto;}

  .tall-35  {  min-height: 35vh;   }
  .tall-50  {  min-height: 50vh;   }
  .tall-90 {   min-height: 90vh;  }
  .tall-100 {  min-height: 100vh;  }
  .tall-110 {  min-height: 110vh;  }

  .border-05 { border: 0.5rem solid white; }

  // Bump

  .bump-up-8 {  margin-top: -8rem; }

  .bump-up-25 {  margin-top: -25vh;  }

  .bump-up-40 { margin-top: -40vh;   }

  .dark-bg      {  background-color: #d7b1a2;  }
  .v-light-bg     {  background-color:#f8f6f4;  }
  .light-bg     {  background-color: #F2EEE9;  }
  .featured-bg  {  background-color: #e8e1d9;  }
  .grey-bg      {  background-color: #f6f6f6;  }

  .bg-split-white-tan {
    background: linear-gradient(180deg, white 50%, #F2EEE9 50%);
  }

  .bg-split-tan-white {
    background: linear-gradient(180deg, #F2EEE9 50%, white 50%);
  }

  .bg-v-white-tan {
    background: linear-gradient(90deg, white 20%, #F2EEE9 0);
  }


  .light-text   { color: white; }
  .flex-flip:nth-child(2n){  flex-direction: row-reverse; }

  .highlighted-text {
    background-color: #FDFAF7;
    box-shadow: 15px 0px 0px 2px  #FDFAF7, -10px 0px 0px 2px  #FDFAF7;
    line-height: 1.7;
    font-size: 1.1rem;
  }

  .columns {
    column-count: 2;
    column-gap: 2rem;
  }

.sticky-top {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.border-center {position: relative;}

.border-center:after {
  content:"";
  position: absolute;
  pointer-events:none;
  top: 0;
  bottom: 0;
  left: 50%;
  border-left: 2px solid rgb(90,90,90);
  transform: translate(-50%);
}

@media(max-width:768px) {
  .border-center:after {
    content: none;
  }
}

  /* General typography */

  body {
    font-family: orpheuspro, serif;
    color: #414141;
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1;
    margin-bottom: 0.8rem;
    font-weight: normal;
  }

  .subtle-heading {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
  }

  h1 {  font-size: 2.5rem;  }
  h2  { font-size: 2.2rem;  }
  h3  { font-size: 1.8rem;  }
  h4  { font-size: 1.4rem;  }
  p, li  { font-size: 1.1rem;  }

  p, ul {
     margin-bottom: 1rem;
     line-height: 1.6;
  }

  ul { padding-left: 1.5rem; }
  li { padding-left: 1rem;
       margin-bottom: 0.5rem;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p a {
    border-bottom: 1px solid;
    line-height: 1.3;
    display: inline-block;
  }

  p a:hover {
    color: #be9a85;
  }

  p a.emphasis {
    font-style: italic;
  }

  .blog-content h2:not(:first-child),
  .blog-content h3:not(:first-child),
  .blog-content h4:not(:first-child) {
    margin-top: 2rem;
  }

  .text-animation .emphasis {
    min-width: 8rem;
    min-height: 2rem;
    display:inline-block;
  }

  .text-animation .emphasis span {
    position: relative;
    padding: 0.5rem;
  }

  .text-animation .emphasis span:hover {
    // font-style: italic;
    color: #be9a85;
  }

  // for the IG feed
  .light-text .emphasis span:hover {
    color: #f9f0e7;
  }
  .light-text .emphasis span:before {
    background: #f9f0e7;
  }

 // This creates the animated underline effect

  a.emphasis span:before {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    content: '';
    width: 0;
    height: 1px;
    background: #be9a85;
    display: block;
    bottom: 2px;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
  }

  a.emphasis span:hover:before {
    width: 100%;
  }

  /* this makes sure that the last element in any container doesn't have margin-bottom */
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child,
  h6:last-child,
  p:last-child {
    margin-bottom:0;
  }

  .capitalize {
    text-transform: capitalize;
  }

  hr { /* this is a line element */
    border: none;
    border-bottom: 2px solid;
    width: 4rem;
    margin-bottom: 1rem;
  }

  hr.centered {
    margin: 1rem auto;
  }

  hr.wide {
    width: 8rem;
    margin: 2rem auto;
  }

  // Logo & Unique Typography

  .home-banner .logo {
    font-family: "Source Sans Pro";
    font-weight: bold;
    font-size: 8vw;
    line-height: 1.1;
    border-bottom: 3px solid;
    margin-right: -6rem;
    padding-right: 8rem;
    margin-bottom: 10%;
    position: relative;
    z-index: 2;
    padding-bottom: 1rem;
  }

  .big-bold,
  .med-bold,
  .sm-bold {
    font-family: "Source Sans Pro";
    font-weight: bold;
    font-size: 10vw;
    letter-spacing: -0.2vw;
  }

  .med-bold {
    font-size: 6vw;
  }

  .sm-bold {
    font-size: 2.5rem;
    letter-spacing: -0.1vw;
  }

  .border-left    { border-left: 2px solid }
  .border-right   { border-right: 2px solid }
  .border-top     { border-top: 2px solid }
  .border-bottom  { border-left: 2px solid }

  /* ------- unique typography styles - adjust to suit your styles  --------- */

  .not-bold   { font-weight: normal; } /* add this class to make headings not bold */
  .no-bullets { list-style:none; } /* Used to remove dot-points from a list */

  p.emphasis {   /* typically added to paragraphs to make them a little bigger, could be a different font or color also */
    font-size: 1.2rem;
  }

  p.subtle,
  .subtle p {
    font-size: 0.9rem;
  }

  p.subtitle {
    font-size: 1rem;
    font-style: italic;
  }

  /* Icons */

  .far {        /* default styles for font awesome icons */
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .featured-stat {     /* styles for our featured statistic icons  */
    font-size: 3rem;
    color: #be9a85;
    margin-bottom: 1rem;
    display: block;
  }

  .highlight { /* this is applied to spans for out highlighted text. */
    background-color: rgb(240, 240, 245);
    display: inline-block;
    padding: 0.5rem;
    margin: 2px;
  }

  .quote-icon {  /* You can also change the fonts for these icons specifically */
    font-size: 6rem;
    line-height: 1;
  }

  // Editor Links

.editor-link {
  display: none;
  position: absolute;
  right: 1rem;
  margin-top: 1rem;
  color: red;
  padding: 0.2rem 1rem;
  border: 1px solid;
  border-radius: 50px;
}

.cms-editor-active .editor-link {
  display: block;
}


@media(max-width:900px) {
    .home-banner .logo {
        font-size: 2.5rem;
    }
}

@media(max-width:768px) {
    .mob-p-0 { padding: 0;  }
    .mob-p-5 { padding: 5%;  }
    .mob-p-t-0 { padding-top: 0;}
    .mob-p-b-0 { padding-bottom: 0;}
  .home-banner .logo {
    margin: 0;
    border-bottom: none;
    padding:0;
  }

}
