
.mob-show {
  display: none;
}

/* This media query kicks into action at the gridlex sm breakpoint */
@media(max-width:768px) {

  .mob-show {display: block;}

  .sticky-top {
    position: static;
  }

  .mob-hide {
    display: none;
  }
  .hamburger{
    display: block;
  }

  .blog-item-wide .bg-image{
    padding-bottom: 60%;
  }

  #body .text-bump {
    margin: -0.5rem;
  }

  .capped-width {
    width: 100%;
  }

  .mob-flex-flip {
    flex-direction: column-reverse;
  }

  .columns {
    column-count: 1;
  }
  .blog-masonry {
    column-count: 1;
  }

  .social-share {
    left: 0.5rem;
  }

    .about-page p {
      font-size: 1.5rem;
    }

  footer {
    padding: 2rem 5%;
  }

  footer,
  footer .right {
    text-align: center;
  }

  .mob-order-1 {
    order: -1;
  }

  // Horizontal Scroll

  .mob-horizontal-scroll {
    overflow-x: auto;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: auto;
  }
  
} /* end media query */
